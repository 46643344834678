.p-group-post-detail > main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.p-group-post-detail .content-body {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  background-color: #fff;
}

.post-title {
  margin-top: 0.2rem;
  font-size: 1.5em;
}

.description {
  margin-top: 0.2rem;
  color: #ccc;
  font-size: 0.28rem;
}

.description > span {
  color: #59b6d7;
}