.p-group-permission .list > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 1rem;
  background-color: #fff;
  padding: 0 0.3rem;
}

.p-group-permission .list > li + li {
  border-top: 1px solid #ededed;
}

.p-group-permission .list > li.active::after {
  content: "✓";
  color: #59b6d7;
}