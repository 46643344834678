.image-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.image-wrap {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  overflow: hidden;
  position: relative;
  margin: 0.5%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #ededed;
}

.image-wrap.img1 {
  -ms-flex-preferred-size: 99%;
  flex-basis: 99%;
}

.image-wrap.img3 {
  -ms-flex-preferred-size: 24%;
  flex-basis: 24%;
}

.image-wrap.loading:before {
  content: "";
}

.image-placeholder {
  padding-top: 100%;
}

.compose-image {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.compose-image.loading {
  opacity: 0.3;
}

.m-rpic-edit {
  z-index: 9;
  position: relative;
  background: transparent;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.m-rpic-edit-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.5rem;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 0.24rem;
  color: #fff;
  z-index: 1;
}

.m-rpic-edit-wrap .m-svg-def {
  width: 0.32rem;
  height: 0.32rem;
  margin-right: 0.1rem;
}

.m-rpic-close {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #000;
  opacity: 0.4;
  padding: 0;
  border: 0;
  outline: none;
  color: #fff;
  z-index: 3;
}

.m-rpic-close .m-style-svg {
  width: 0.42rem;
  height: 0.42rem;
}

.more-image {
  border: 1px dashed #ccc;
  color: #ccc;
}