.c-group-user-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 1.34rem;
  border-bottom: 1px solid #ededed;
  padding: 0 0.3rem;
}

.c-group-user-item .avatar {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  margin-left: 0;
  margin-right: 0.3rem;
}

.c-group-user-item .admin,
.c-group-user-item .founder {
  display: inline-block;
  background-color: #ccc;
  border-radius: 1rem;
  font-size: 0.22rem;
  margin-left: 0.3rem;
  color: #fff;
  text-align: center;
  width: 4em;
  height: 0.3rem;
  line-height: 0.3rem;
}

.c-group-user-item .founder {
  background-color: #fca308;
}