.p-group-detail {
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.p-group-detail .m-head-top {
  padding: 0 0.2rem;
}

.p-group-detail > header {
  border-bottom: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.p-group-detail > header.bg-transp {
  color: #fff;
  background-color: transparent;
}

.p-group-detail > header.show-title {
  background-image: none;
  background-color: #fff;
  border-bottom: 1px solid #ededed;
  color: #000;
}

.p-group-detail > header.show-title .m-trans-y {
  -webkit-transform: none;
  transform: none;
}

.p-group-detail > header .m-trans-y {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.p-group-detail .slide-more {
  position: fixed;
  right: -4rem;
  top: 0;
  bottom: 0;
  width: 4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #363845;
  color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  z-index: 11;
  padding: 0.4rem;
}

.p-group-detail .slide-more .list {
  margin-top: 1.2rem;
  border-top: 1px solid #4a4d5e;
}

.p-group-detail .slide-more .list > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 1rem;
  border-bottom: 1px solid #4a4d5e;
  font-size: 0.3rem;
}

.p-group-detail .slide-more .list > li svg {
  vertical-align: sub;
  color: #4a4d5e;
}

.p-group-detail .slide-more .btn-quit {
  width: 100%;
  border-radius: 0.08rem;
  height: 0.6rem;
  background-color: transparent;
  color: #ccc;
  border: 1px solid #4a4d5e;
  font-size: 0.3rem;
}

.p-group-detail .slide-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: none;
}

.p-group-detail.show-slide {
  margin-left: -4rem;
  padding-right: 4rem;
}

.p-group-detail.show-slide > header {
  margin-left: -4rem;
}

.p-group-detail.show-slide .slide-more {
  right: 0;
}

.p-group-detail.show-slide .slide-mask {
  display: block;
}

.p-group-detail .address {
  display: inline-block;
  max-width: 20em;
}

.p-group-detail .create-post {
  position: fixed;
  bottom: 0.4rem;
  height: 0.6rem;
  width: 0.6rem;
  left: 50%;
  padding: 0;
  border-radius: 100%;
  margin-left: -0.3rem;
  background-color: #59b6d7;
  border: 0.02rem solid #fff;
  -webkit-box-shadow: 0 0 0.12rem 0 rgba(89, 182, 215, 0.43);
  box-shadow: 0 0 0.12rem 0 rgba(89, 182, 215, 0.43);
  z-index: 1;
}

.p-group-detail .create-post > svg {
  display: block;
  margin: auto;
}

.p-group-detail-banner {
  padding-top: 50%;
  width: 100%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  background-size: cover;
  background-position: center;
  font-size: 0.28rem;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

.p-group-detail-banner h3 {
  font-size: 0.32rem;
}

.p-group-detail-banner p span + span {
  margin-left: 0.4rem;
}

.p-group-detail-banner p i {
  margin: 0 0.05rem;
}

.p-group-detail-banner .p-group-detail-avatar {
  margin-right: 0.2rem;
  overflow: hidden;
  width: 1.4rem;
  height: 1.4rem;
  border: 0.02rem solid #fff;
}

.p-group-detail-banner .p-group-detail-avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.p-group-detail-banner:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  margin: auto;
  -webkit-filter: blur(0.15rem);
  filter: blur(0.15rem);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  background: inherit;
}

.p-group-detail-bg-mask {
  padding: 0 0.25rem 0.5rem;
  background-color: rgba(124, 124, 124, 0.2);
}

.p-group-detail-info {
  background-color: #fff;
  padding: 0.3rem 0.2rem;
  font-size: 0.26rem;
  line-height: 0.36rem;
  color: #999;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.p-group-detail-info p + p {
  margin-top: 0.1rem;
}

.p-group-detail-tag {
  margin-top: 0.2rem;
  margin-left: 0.1rem;
  display: inline-block;
  padding: 0.05rem 0.2rem;
  font-size: 0.24rem;
  background-color: rgba(102, 102, 102, 0.1);
  border-radius: 0.18rem;
}

.p-group-detail-filter {
  position: relative;
}

.p-group-detail-filter-box {
  padding: 0.25rem 0.2rem;
  color: #999;
  font-size: 0.26rem;
  position: sticky;
  top: 0.88rem;
  z-index: 9;
  background-color: #f4f5f6;
}

.p-group-detail-filter-box .m-style-svg {
  margin-left: 0.2rem;
}

.p-group-detail-filter-options {
  overflow: hidden;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 9;
  min-width: 2rem;
  border-radius: 0.08rem;
  background-color: #fff;
  -webkit-transform: translate3d(0, 0.25rem, 0);
  transform: translate3d(0, 0.25rem, 0);
  -webkit-box-shadow: 0 0 0.1rem 0 rgba(221, 221, 221, 0.6);
  box-shadow: 0 0 10px 0 rgba(221, 221, 221, 0.6);
}

.p-group-detail-filter-options li {
  padding: 0.25rem 0.2rem;
  font-size: 0.24rem;
  color: #999;
}

.p-group-detail-filter-options li + li {
  border-top: 1px solid #ededed;
}

.p-group-detail-feeds li + li {
  margin-top: 0.1rem;
}

.group-item-action {
  margin-bottom: 0.8rem;
}

.group-item-action button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 1.26rem;
  height: 0.5rem;
  border: 1px solid currentColor;
  border-radius: 0.08rem;
  background-color: #fff;
  font-size: 0.24rem;
  color: #59b6d7;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.group-item-action button span {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-left: 0.05rem;
}

.group-item-action button[disabled] {
  color: #ededed;
  cursor: not-allowed;
}

.group-item-action button[disabled] span {
  color: #999;
}

.group-item-action button[disabled] svg {
  opacity: 0.5;
}

.group-item-action.c_fff button {
  background-color: transparent;
  color: #fff;
}

.white {
  color: #fff;
}