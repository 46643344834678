.feed-detail-video {
  height: 100vw;
  width: 100vw;
  margin-left: -0.2rem;
  background: #000;
}

.m-art {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}

.username {
  font-size: 0.32rem;
  margin-left: 0.1rem;
  text-align: center;
}

.topics {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.2rem 0 0;
}

.topics .topic-item {
  padding: 0 0.16rem;
  border-radius: 0.06rem;
  background-color: rgba(145, 209, 232, 0.12);
  font-size: 0.24rem;
  color: #59b6d7;
  margin-right: 0.12rem;
  margin-bottom: 0.12rem;
  cursor: pointer;
}