.p-group-list .common-header {
  position: fixed;
  top: 0;
}

.p-group-list .groups-nav {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0.3rem;
  top: 0.9rem;
  height: 0.9rem;
  left: 0;
  right: 0;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  font-size: 0.26rem;
  color: #999;
  overflow-y: auto;
  border-bottom: 1px solid #ededed;
  z-index: 10;
  max-width: 10.8rem;
  background-color: #fff;
}

.p-group-list .groups-nav .item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.p-group-list .groups-nav .item + .item {
  margin-left: 0.5rem;
}

.p-group-list .groups-nav .item.active {
  color: #333;
  font-size: 0.3rem;
}

.p-group-list > main {
  padding-top: 1.8rem;
}

.p-group-list > main .group-item-wrap + .group-item-wrap {
  border-top: 1px solid #ededed;
}