.m-image-paid-option-box {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 120;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 0 0.5rem;
  width: 6.5rem;
  border-radius: 0.1rem;
  background-color: #fff;
}

.m-image-paid-option-head {
  padding: 0.4rem 0;
  color: #333;
  text-align: center;
  font-size: 0.32rem;
  border-bottom: 1px solid #ededed;
}

.m-image-paid-option-row {
  font-size: 0.32rem;
  padding: 0.4rem 0;
}

.m-image-paid-option-row .m-image-paid-option-radio,
.m-image-paid-option-row .m-pinned-amount-btn {
  padding: 0 0.2rem;
  height: 0.7rem;
  border-radius: 0.06rem;
  border: 1px solid #dedede;
  margin: 0 0.2rem;
}

.m-image-paid-option-row .m-image-paid-option-radio.active,
.m-image-paid-option-row .m-pinned-amount-btn.active {
  color: #59b6d7;
  border-color: #59b6d7;
}

.m-image-paid-option-label {
  font-size: 0.26rem;
  color: #999;
  margin-bottom: 0.3rem;
}