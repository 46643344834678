.p-choose-group {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
  background-color: #f4f5f5;
}

.p-choose-group .list > li + li {
  border-top: 1px solid #ededed;
}

.p-choose-group .no-groups {
  padding-top: 1.8rem;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #999;
}

.p-choose-group .no-groups > button {
  background-color: #59b6d7;
  color: #fff;
  border-radius: 0.08rem;
  padding: 0.15rem 1.2rem;
  font-size: 0.32rem;
  margin-top: 0.3rem;
}