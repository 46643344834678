.p-search-post {
  height: 100%;
}

.p-search-post /deep/ .c-search-bar {
  top: 0;
}

.p-search-post /deep/ .jo-loadmore-main {
  margin-top: 0.9rem;
}

.p-search-post .m-no-find {
  height: -webkit-calc(100% - 0.9rem);
  height: calc(100% - 0.9rem);
}