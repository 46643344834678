.p-group-home .group-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 0.1rem;
  background-color: #fff;
  font-size: 0.24rem;
  color: #999;
  padding: 0.2rem;
}

.p-group-home .group-label strong {
  font-size: 0.4rem;
  color: #f76c69;
}

.p-group-home .group-label .m-svg-small {
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.p-group-home .group-list li {
  border-top: 1px solid #ededed;
}