.p-group-protocol {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  background-color: #fff;
}

.p-group-protocol .markdown-body {
  font-size: 0.28rem;
  color: #666;
  padding: 0.2rem;
  height: -webkit-calc(100% - 0.9rem);
  height: calc(100% - 0.9rem);
  overflow-y: auto;
}