.p-group-search {
  height: 100%;
}

.p-group-search .loadmore {
  padding-top: 1.8rem;
  height: 100%;
}

.p-group-search .post-list .post-card {
  margin-bottom: 0.1rem;
}

.p-group-search .search-bar {
  position: fixed;
}

.p-group-search .nav-tab {
  position: fixed;
  top: 0.9rem;
}

.p-group-search .m-no-find {
  position: fixed;
  top: 0;
  width: 100%;
}

.p-group-search .no-groups {
  padding-top: 1.8rem;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #999;
}

.p-group-search .no-groups > button {
  background-color: #59b6d7;
  color: #fff;
  border-radius: 0.08rem;
  padding: 0.15rem 1.2rem;
  font-size: 0.32rem;
  margin-top: 0.3rem;
}