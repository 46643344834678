.p-group-post-create {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
}

.p-group-post-create .disabled {
  color: #ccc;
}

.p-group-post-create .form-select-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.3rem;
  border-bottom: 1px solid #ededed;
}

.p-group-post-create .form-select-item .input-wrap svg {
  vertical-align: middle;
}

.p-group-post-create .form-select-item .placeholder {
  margin-right: 0.2rem;
  color: #999;
}

.p-group-post-create > main {
  padding: 0.3rem;
}

.p-group-post-create > main .title-wrap {
  padding-bottom: 0.15rem;
  border-bottom: 0.02rem solid #ededed;
}

.p-group-post-create > main .content-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 0.15rem;
}

.p-group-post-create > main .content-wrap .textarea-shadow {
  opacity: 0;
  min-height: 1rem;
  padding: 0.28rem 0;
  word-wrap: break-word;
  word-break: break-all;
}

.p-group-post-create > main .content-wrap textarea {
  position: absolute;
  top: 0;
  display: block;
  font-size: 0.3rem;
  width: 100%;
  height: 100%;
  overflow: scroll;
  resize: none;
  outline: none;
  min-height: 1rem;
  background-color: transparent;
  word-wrap: break-word;
  word-break: break-all;
}

.p-group-post-create > main input,
.p-group-post-create > main textarea {
  width: 100%;
  height: 100%;
  font-size: 0.3rem;
}

.p-group-post-create .form-switch-item {
  position: fixed;
  bottom: 0;
  border-top: 1px solid #ededed;
}