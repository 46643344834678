.c-form-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 1rem;
  font-size: 0.3rem;
  padding: 0 0.2rem;
  color: #999;
  border-bottom: 1px solid #ededed;
}

.c-form-item > label {
  display: inline-block;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 4em;
}

.c-form-item .input-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  height: 100%;
  padding-right: 0.2rem;
}

.c-form-item .placeholder {
  color: #ccc;
}

form .c-form-item {
  border-bottom: none;
}

form .input-wrap {
  border-bottom: 1px solid #ededed;
}

.p-group-info .c-form-item {
  background-color: #fff;
}

.p-group-info .c-form-item > label {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}

.p-group-info .c-form-item /deep/ .input-wrap {
  color: #333;
}

.p-group-info .c-form-item /deep/ .input-wrap > input {
  color: #333;
}

.p-group-info .c-form-item /deep/ .c-textarea-input .textarea {
  color: #333;
}

.p-group-info .c-form-switch-item {
  border-bottom: 1px solid #ededed;
}

.p-group-info .footage {
  color: #b3b3b3;
  font-size: 0.2rem;
  margin: 0.1rem 0.14rem 0.3rem;
}

.p-group-info .check-label {
  display: block;
  position: relative;
  padding-left: 0.48rem;
  margin-bottom: 0.12rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
}

.p-group-info .check-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.p-group-info .check-label input[value="true"] ~ .checkmark::after {
  display: block;
}

.p-group-info .check-label .checkmark {
  position: absolute;
  top: 0.08rem;
  left: 0;
  height: 0.3rem;
  width: 0.3rem;
  border: 1px solid #ededed;
  vertical-align: middle;
}

.p-group-info .check-label .checkmark::after {
  content: "";
  position: absolute;
  display: none;
  left: 0.08rem;
  top: 0;
  width: 0.12rem;
  height: 0.24rem;
  border: solid #70d0ab;
  border-width: 0 0.03rem 0.03rem 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.p-group-info .label-amount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  text-align: right;
  padding-right: 0.2rem;
  width: 10em;
}

.p-group-info .label-amount input {
  display: inline-block;
  height: 100%;
  width: 100%;
  text-align: right;
  margin-right: 0.2rem;
  font-size: 0.3rem;
}

.p-group-info .label-amount > span {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

.bb {
  border-bottom: 1px solid #ededed;
}